// App.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaSearch, FaPlus } from 'react-icons/fa';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import logo from './el_barrio_logo.png';
import './index.css';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

// Import components
import AdminPanel from './components/AdminPanel';
import Home from './components/Home';
import Order from './components/Order';
import MyPoints from './components/MyPoints';
import Profile from './components/Profile';
import Review from './components/Review';
import ShareAndEarn from './components/ShareAndEarn';
import AnimatedHamburger from './components/AnimatedHamburger';
import Txtr from './components/Txtr';

import io from 'socket.io-client';

const socket = io('https://barriobucks.com', {
  transports: ['websocket', 'polling'],
  withCredentials: true,
});

const clearStorage = () => {
  localStorage.removeItem('customer');
  sessionStorage.removeItem('customer');
};

// Create Axios instance
const api = axios.create({
  baseURL: 'https://barriobucks.com/api',
  withCredentials: true,
});

const MessageInput = React.memo(({ onSendMessage, selectedThread }) => {
  const [newMessage, setNewMessage] = useState('');
  const [attachments, setAttachments] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setAttachments(prevAttachments => [...prevAttachments, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage.trim() || attachments.length > 0) {
      onSendMessage(newMessage, attachments);
      setNewMessage('');
      setAttachments([]);
    }
  };

  const removeAttachment = (index) => {
    setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== index));
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <div className="flex mb-2">
        <input
          type="text"
          placeholder="Type a message..."
          className="flex-grow p-2 rounded-l text-black"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button type="submit" className="bg-blue-500 text-white p-2 rounded-r">
          Send
        </button>
      </div>
      <div {...getRootProps()} className={`dropzone p-2 border-2 border-dashed ${isDragActive ? 'border-blue-500 bg-blue-100' : 'border-gray-300'}`}>
        <input {...getInputProps()} />
        <p>{isDragActive ? "Drop the files here..." : "Drag 'n' drop files here, or click to select files"}</p>
      </div>
      {attachments.length > 0 && (
        <div className="mt-2">
          <p className="text-sm font-semibold">Attachments:</p>
          <ul className="list-disc pl-5">
            {attachments.map((file, index) => (
              <li key={index} className="flex items-center">
                <span className="mr-2">{file.name}</span>
                <button
                  type="button"
                  onClick={() => removeAttachment(index)}
                  className="text-red-500 text-sm"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </form>
  );
});

function AppContent() {
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTxtrLoaded, setIsTxtrLoaded] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [showMessageInput, setShowMessageInput] = useState(false);
  const [messageText, setMessageText] = useState('');
  const txtrRef = useRef();
  const [threads, setThreads] = useState([]);

  useEffect(() => {
    console.log('Customer state changed:', customer);
  }, [customer]);

  useEffect(() => {
    const storedCustomer = localStorage.getItem('customer') || sessionStorage.getItem('customer');
    if (storedCustomer) {
      try {
        const customerData = JSON.parse(storedCustomer);
        setCustomer(customerData);
        setIsAuthenticated(true);
        setIsAdmin(customerData.isAdmin);
      } catch (error) {
        console.error('Error parsing stored customer data:', error);
        clearStorage();
      }
    }
    checkSessionStatus(); // Always check session status on initial load
  }, []);


  const handleNewMessage = useCallback((message) => {
    if (txtrRef.current) {
      txtrRef.current.addNewMessage(message);
      txtrRef.current.updateThread(message.from_number, message.body);
    }
  }, []);

  
  useEffect(() => {
    if (isAdmin && isTxtrLoaded) {
      socket.on('newMessage', handleNewMessage);
      
      socket.on('outboundMessage', (message) => {
        if (txtrRef.current) {
          txtrRef.current.handleOutboundMessage(message);
        }
      });
  
      return () => {
        socket.off('newMessage', handleNewMessage);
        socket.off('outboundMessage');
      };
    }
  }, [isAdmin, isTxtrLoaded, handleNewMessage]);


  const isValidPhoneNumber = (input) => {
    return /^\d{10}$/.test(input.replace(/\D/g, ''));
  };

  const handleSearch = async () => {
    const searchTermLower = searchTerm.toLowerCase().trim();
    const containsLetters = /[a-zA-Z]/.test(searchTermLower);
    const phoneNumberMatch = searchTermLower.replace(/\D/g, '');
  
    if (!containsLetters && phoneNumberMatch.length > 0) {
      // Phone number search
      try {
        const response = await api.get(`/customer-by-phone?phone=${phoneNumberMatch}`);
        let customer = response.data.customer;
  
        if (!customer) {
          const name = prompt("Enter customer name:");
          if (name) {
            const newCustomerResponse = await api.post('/create-customer', {
              phone: formatPhoneNumber(phoneNumberMatch),
              name: name,
              since: new Date().getFullYear().toString(),
              last_order: new Date().toISOString().split('T')[0],
              orders: 0,
              bb_points: 0,
              barrio_bucks: 0
            });
            customer = newCustomerResponse.data.customer;
          } else {
            return;
          }
        }
  
        const messageBody = prompt("Enter your message:");
        if (messageBody) {
          const messageResponse = await api.post('/send-message', {
            to: customer.phone,
            body: messageBody
          });
  
          if (txtrRef.current) {
            const newThread = {
              phone_number: customer.phone,
              customerName: customer.name,
              last_message: messageBody,
              timestamp: new Date().toISOString()
            };
            txtrRef.current.addNewThread(newThread);
            txtrRef.current.addNewMessage(messageResponse.data);
            txtrRef.current.refreshMessages();
          }
  
          setSearchTerm('');
          setSelectedThread({
            phone_number: customer.phone,
            customerName: customer.name
          });
        }
      } catch (error) {
        console.error('Error in handleSearch:', error);
      }
    } else {
      // Name search
      if (txtrRef.current) {
        txtrRef.current.updateSearchTerm(searchTerm);
      }
    }
  };
  
  const handleExistingCustomer = (customer) => {
    const newThread = {
      phone_number: customer.phone,
      customerName: customer.name,
      last_message: '',
      timestamp: new Date().toISOString()
    };
    setSelectedThread(newThread);
    if (txtrRef.current) {
      txtrRef.current.addNewThread(newThread);
    }
    setSearchTerm('');
    setShowMessageInput(true);
  };

  const handleThreadSelect = useCallback((thread) => {
    setSelectedThread(thread);
    setSearchTerm('');
    if (txtrRef.current) {
      txtrRef.current.updateSearchTerm('');
    }
  }, []);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };
  
  const createNewCustomerAndThread = async (phoneNumber, name) => {
    try {
      console.log('Creating new customer:', { phoneNumber, name });
      const currentDate = new Date().toISOString().split('T')[0];
      const formattedPhone = formatPhoneNumber(phoneNumber);
      
      if (!formattedPhone) {
        throw new Error('Invalid phone number');
      }
      
      const response = await api.post('/create-customer', {
        phone: formattedPhone,
        name: name,
        since: new Date().getFullYear().toString(),
        last_order: currentDate,
        orders: 0,
        bb_points: 0,
        barrio_bucks: 0
      });
      
      console.log('Create customer response:', response.data);
      const newCustomer = response.data.customer;
      const newThread = {
        phone_number: formattedPhone,
        customerName: name,
        last_message: '',
        timestamp: new Date().toISOString()
      };
      setSelectedThread(newThread);
      if (txtrRef.current) {
        txtrRef.current.addNewThread(newThread);
      }
      setSearchTerm('');
      setShowMessageInput(true);
    } catch (error) {
      console.error('Error creating new customer:', error.response ? error.response.data : error);
      // Show error message to the user
    }
  };

  const checkSessionStatus = useCallback(async () => {
    try {
      const response = await api.get('/check-session');
      if (response.data.isLoggedIn) {
        setIsAuthenticated(true);
        setIsAdmin(response.data.isAdmin);
        if (response.data.customer) {
          setCustomer(response.data.customer);
          localStorage.setItem('customer', JSON.stringify(response.data.customer));
        }
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
        setCustomer(null);
        clearStorage();
      }
    } catch (error) {
      console.error('Error checking session:', error);
      setIsAuthenticated(false);
      setIsAdmin(false);
      setCustomer(null);
      clearStorage();
    }
  }, []);

  useEffect(() => {
    checkSessionStatus();
  }, [checkSessionStatus]);

  const handleLogin = useCallback((customerData) => {
    setCustomer(customerData);
    setIsAuthenticated(true);
    setIsAdmin(customerData.isAdmin);
    localStorage.setItem('customer', JSON.stringify(customerData));
  }, []);

  const handleLogout = useCallback(() => {
    api.post('/api/logout')
      .then(() => {
        setCustomer(null);
        setIsAuthenticated(false);
        setIsAdmin(false);
        setIsTxtrLoaded(false);
        localStorage.removeItem('customer');
        sessionStorage.removeItem('customer');
        setIsNavOpen(false);
        setSearchTerm('');
        setSelectedThread(null);
        setMessages([]);
        navigate('/', { replace: true });
        window.location.reload();
      })
      .catch(error => {
        console.error('Error during logout:', error);
        // Perform local logout even if server logout fails
        setCustomer(null);
        setIsAuthenticated(false);
        setIsAdmin(false);
        localStorage.removeItem('customer');
        sessionStorage.removeItem('customer');
        navigate('/', { replace: true });
        window.location.reload();
      });
  }, [navigate]);

  const handleSendMessage = async (newMessage, attachments) => {
    if (!selectedThread || (!newMessage.trim() && attachments.length === 0)) return;
  
    const formData = new FormData();
    formData.append('to', selectedThread.phone_number);
    formData.append('body', newMessage);
    
    attachments.forEach((file) => {
      formData.append('attachments', file);
    });
  
    try {
      const response = await api.post('/send-message', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
  
      console.log('Message sent:', response.data);
  
      if (txtrRef.current) {
        txtrRef.current.addNewMessage(response.data);
        txtrRef.current.updateThread(selectedThread.phone_number, newMessage);
      }
  
      setMessageText('');
      setShowMessageInput(false);
    } catch (error) {
      console.error('Error sending message:', error);
      // Show error message to the user
    }
  };

  return (
    <div className="h-screen bg-gray-900 text-white relative">
      <header className="fixed top-0 left-0 right-0 z-50 bg-gray-900 shadow-lg" style={{height: '80px'}}>
        <div className="container mx-auto px-4 h-full flex justify-between items-center">
          <div className="flex items-center">
            <img src={logo} alt="El Barrio logo" className="h-12 w-auto mr-4" />
            <span className="text-xl md:text-2xl font-bold truncate">El Barrio Customer Hub</span>
          </div>
          <nav className="hidden md:flex space-x-2 lg:space-x-4">
            <Link to="/" className="hover:text-blue-400 text-[clamp(0.7rem,1.5vw,1rem)]">Home</Link>
            <Link to="/order" className="hover:text-blue-400 text-[clamp(0.7rem,1.5vw,1rem)]">Order</Link>
            <Link to="/review" className="hover:text-blue-400 text-[clamp(0.7rem,1.5vw,1rem)]">Review</Link>
            <Link to="/share" className="hover:text-blue-400 text-[clamp(0.7rem,1.5vw,1rem)]">Share</Link>
            {isAuthenticated && (
              <Link to="/profile" className="hover:text-blue-400 text-[clamp(0.7rem,1.5vw,1rem)]">Profile</Link>
            )}
            {isAuthenticated && isAdmin && (
              <>
                <Link to="/admin" className="hover:text-blue-400 text-[clamp(0.7rem,1.5vw,1rem)]">Admin</Link>
                <Link to="/txtr" className="hover:text-blue-400 text-[clamp(0.7rem,1.5vw,1rem)]">Txtr</Link>
              </>
            )}
            {isAuthenticated && (
              <button onClick={handleLogout} className="hover:text-blue-400 text-[clamp(0.7rem,1.5vw,1rem)]">
                Logout
              </button>
            )}
          </nav>
          <div className="md:hidden">
            <AnimatedHamburger isOpen={isNavOpen} toggle={() => setIsNavOpen(!isNavOpen)} />
          </div>
        {isAdmin && isTxtrLoaded && (
          <div className="relative flex-grow max-w-xs md:max-w-sm">
            <input
              type="text"
              placeholder="Search threads or add new..."
              className="w-full p-2 pr-10 rounded text-black"
              value={searchTerm}
              onChange={(e) => {
                const newSearchTerm = e.target.value;
                setSearchTerm(newSearchTerm);
                const normalizedInput = newSearchTerm.replace(/\D/g, '');
                const isNewCustomer = isValidPhoneNumber(normalizedInput) && 
                  !threads.some(t => t.phone_number.replace(/\D/g, '') === normalizedInput);
                setIsNewCustomer(isNewCustomer);
                
                if (txtrRef.current) {
                  txtrRef.current.updateSearchTerm(newSearchTerm);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-blue-500"
              onClick={handleSearch}
            >
              {isNewCustomer ? <FaPlus className="text-green-500" /> : <FaSearch />}
            </button>
          </div>
        )}
        </div>
      </header>

      {isNavOpen && (
        <nav className="md:hidden bg-gray-800 p-4 fixed top-20 left-0 right-0 z-40">
          <Link to="/" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Home</Link>
          <Link to="/order" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Order</Link>
          <Link to="/review" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Review Us</Link>
          <Link to="/share" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Share & Earn</Link>
          {isAuthenticated && (
            <Link to="/profile" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Profile</Link>
          )}
          {isAuthenticated && isAdmin && (
            <>
              <Link to="/admin" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Admin</Link>
              <Link to="/txtr" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Txtr</Link>
            </>
          )}
          {isAuthenticated && (
            <button onClick={handleLogout} className="block py-2 hover:text-blue-400">Logout</button>
          )}
        </nav>
      )}

      <main className="pt-28 pb-16 h-full overflow-hidden">
        <div className="h-full overflow-y-auto">
        <Routes>
          <Route path="/" element={<Home onLogin={handleLogin} />} />
          <Route path="/order" element={<Order />} />
          <Route path="/review" element={<Review />} />
          <Route path="/share" element={<ShareAndEarn />} />
          {isAuthenticated && (
            <Route
              path="/profile"
              element={<Profile customer={customer} onUpdateCustomer={setCustomer} onLogout={handleLogout} />}
            />
          )}
          {isAuthenticated && isAdmin && (
            <>
              <Route path="/admin" element={<AdminPanel />} />
              <Route
                path="/txtr"
                element={
                  <Txtr
                    ref={txtrRef}
                    searchTerm={searchTerm}
                    setIsTxtrLoaded={setIsTxtrLoaded}
                    selectedThread={selectedThread}
                    setSelectedThread={handleThreadSelect}
                    messages={messages}
                    setMessages={setMessages}
                    threads={threads}
                    setThreads={setThreads}
                  />
                }
              />
            </>
          )}
        </Routes>
        </div>
      </main>

      {isAdmin && isTxtrLoaded && (
        <footer className="fixed bottom-0 left-0 right-0 bg-gray-800 p-4">
          <MessageInput onSendMessage={handleSendMessage} selectedThread={selectedThread} />
        </footer>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;