import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPanel = () => {
  const [searchParams, setSearchParams] = useState({
    names: '',
    startDate: '',
    endDate: '',
  });
  const [selectedTags, setSelectedTags] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [error, setError] = useState('');
  const [sendingProgress, setSendingProgress] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [tagMatchType, setTagMatchType] = useState('all'); // 'all' or 'any'

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await axios.get('https://barriobucks.com/api/tags');
      setAllTags(response.data);
    } catch (err) {
      console.error('Error fetching tags:', err);
      setError('Failed to fetch tags. Please try again.');
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const params = {
        ...searchParams,
        tags: selectedTags.join(','),
        tagMatchType
      };
      const response = await axios.get('https://barriobucks.com/api/admin/customers', { params });
      setCustomers(response.data.map(customer => ({...customer, messageSent: false})));
      setError('');
    } catch (err) {
      setError('Failed to fetch customers. Please try again.');
      console.error('Error fetching customers:', err);
    }
  };

  const handleTagToggle = (tag) => {
    setSelectedTags(prev => 
      prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
    );
  };

  const insertFirstName = () => {
    setMessageText(prevText => prevText + '{{first_name}}');
  };

  const sendMessage = async (customerId) => {
    try {
      const customer = customers.find(c => c.id === customerId);
      if (!customer) throw new Error('Customer not found');

      const firstName = customer.name.split(' ')[0];
      const personalizedMessage = messageText.replace(/{{first_name}}/g, firstName);

      const response = await axios.post('/api/send-message', {
        to: customer.phone,
        body: personalizedMessage
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success) {
        setCustomers(prevCustomers => 
          prevCustomers.map(c => 
            c.id === customerId ? {...c, messageSent: true} : c
          )
        );
      } else {
        throw new Error('Failed to send message');
      }
    } catch (err) {
      console.error('Error sending message:', err);
      setError(`Failed to send message to ${customers.find(c => c.id === customerId)?.name || 'customer'}. Please try again.`);
    }
  };

  const sendAllMessages = async () => {
    setIsSending(true);
    setSendingProgress(0);
    const totalMessages = customers.filter(c => !c.messageSent).length;
    let sentMessages = 0;

    for (let customer of customers) {
      if (!customer.messageSent) {
        await sendMessage(customer.id);
        sentMessages++;
        setSendingProgress(Math.round((sentMessages / totalMessages) * 100));
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay between sends
      }
    }
    setIsSending(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Panel</h1>
      <form onSubmit={handleSearch} className="mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="text"
            name="names"
            placeholder="Customer Names (comma-separated)"
            value={searchParams.names}
            onChange={(e) => setSearchParams({...searchParams, names: e.target.value})}
            className="p-2 border rounded text-black w-full"
          />
          <div className="flex flex-col">
            <div className="flex mb-2">
              <button
                type="button"
                onClick={() => setTagMatchType('all')}
                className={`mr-2 px-3 py-1 rounded ${
                  tagMatchType === 'all' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'
                }`}
              >
                All
              </button>
              <button
                type="button"
                onClick={() => setTagMatchType('any')}
                className={`px-3 py-1 rounded ${
                  tagMatchType === 'any' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'
                }`}
              >
                Any
              </button>
            </div>
            <div className="flex flex-wrap gap-2">
              {allTags.map(tag => (
                <button
                  key={tag}
                  onClick={() => handleTagToggle(tag)}
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    selectedTags.includes(tag)
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-200 text-gray-700'
                  }`}
                  type="button"
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="flex flex-col">
              <label htmlFor="startDate" className="text-sm text-gray-500">From</label>
              <div className="relative">
                <input
                  id="startDate"
                  type="date"
                  name="startDate"
                  value={searchParams.startDate}
                  onChange={(e) => setSearchParams({...searchParams, startDate: e.target.value})}
                  className="p-2 border rounded text-black w-full pr-8"
                />
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="endDate" className="text-sm text-gray-500">To</label>
              <div className="relative">
                <input
                  id="endDate"
                  type="date"
                  name="endDate"
                  value={searchParams.endDate}
                  onChange={(e) => setSearchParams({...searchParams, endDate: e.target.value})}
                  className="p-2 border rounded text-black w-full pr-8"
                />
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
          Search Customers
        </button>
      </form>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="mb-6">
        <textarea
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Enter message here..."
          className="w-full p-2 border rounded text-black"
          rows="4"
        />
        <button 
          onClick={insertFirstName} 
          className="mt-2 bg-green-500 text-white p-2 rounded hover:bg-green-600"
          type="button"
        >
          Add First Name
        </button>
      </div>
      <div>
        <h2 className="text-xl font-semibold mb-2">
          {customers.length > 0 
            ? `${customers.length} Customer${customers.length === 1 ? '' : 's'} Found`
            : 'No Customers Found'}
        </h2>
        <ul>
          {customers.map(customer => (
            <li key={customer.id} className="mb-2 flex flex-col md:flex-row md:items-center md:justify-between">
              <span>{customer.name} - {customer.phone}</span>
              <div className="mt-2 md:mt-0">
                <span className="mr-2 text-sm italic">
                  Preview: {messageText.replace(/{{first_name}}/g, customer.name.split(' ')[0])}
                </span>
                <button 
                  onClick={() => sendMessage(customer.id)}
                  disabled={customer.messageSent}
                  className={`p-2 rounded ${customer.messageSent ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
                  type="button"
                >
                  {customer.messageSent ? 'Sent' : 'Send'}
                </button>
              </div>
            </li>
          ))}
        </ul>
        {isSending && (
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
            <div className="bg-blue-600 h-2.5 rounded-full" style={{width: `${sendingProgress}%`}}></div>
          </div>
        )}
        {customers.length > 0 && (
          <button 
            onClick={sendAllMessages}
            disabled={isSending}
            className={`mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600 ${isSending ? 'opacity-50 cursor-not-allowed' : ''}`}
            type="button"
          >
            {isSending ? `Sending Messages (${sendingProgress}%)` : 'Send All Messages'}
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;