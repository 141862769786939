import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = 'https://barriobucks.com/api';
const API_CUSTOMER_URL = 'https://barriobucks.com/api/customer';

const Profile = ({ customer, onUpdateCustomer, onLogout }) => {
  console.log('Profile component received customer:', customer);

  const [name, setName] = useState(customer?.name || '');
  const [email, setEmail] = useState(customer.email || '');
  const [tags, setTags] = useState(customer.tags || []);
  const [allTags, setAllTags] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationType, setVerificationType] = useState(null);

  useEffect(() => {
    fetchAllTags();
  }, []);

  const fetchAllTags = async () => {
    const url = `${API_CUSTOMER_URL}/tags`;
    console.log('Fetching tags from URL:', url);
    try {
      const response = await axios.get(`${API_CUSTOMER_URL}/tags`);
      setAllTags(response.data);
    } catch (err) {
      console.error('Error fetching tags:', err);
      setError('Failed to load tags. Please try again later.');
    }
  };

  const handleSave = async () => {
    const url = `${API_CUSTOMER_URL}/update`;
    console.log('Updating profile at URL:', url);
    try {
      console.log('Attempting to save profile with:', { id: customer.id, name, email, tags });
      const response = await axios.post(`${API_CUSTOMER_URL}/update`, {
        id: customer.id,
        name,
        email,
        tags
      });
      console.log('Profile update response:', response.data);
      
      if (response.data.emailVerificationRequired) {
        setSuccessMessage('Profile updated. Please check your email to verify your new email address.');
      } else {
        setSuccessMessage('Profile updated successfully.');
      }
      
      onUpdateCustomer(response.data.customer);
      setIsEditing(false);
      setError('');
    } catch (err) {
      console.error('Error updating profile:', err);
      if (err.response) {
        console.error('Error response:', err.response.data);
        setError(`Failed to update profile: ${err.response.data.error || 'Unknown error'}`);
      } else if (err.request) {
        console.error('No response received');
        setError('Failed to update profile: No response from server');
      } else {
        console.error('Error setting up request:', err.message);
        setError(`Failed to update profile: ${err.message}`);
      }
    }
  };

  const handleTagToggle = (tag) => {
    setTags(prevTags => 
      prevTags.includes(tag)
        ? prevTags.filter(t => t !== tag)
        : [...prevTags, tag]
    );
  };

  const initiateVerification = async (type) => {
    try {
      const endpoint = type === 'email' 
        ? `${API_CUSTOMER_URL}/initiate-email-verification`
        : `${API_CUSTOMER_URL}/initiate-phone-verification`;
      
      await axios.post(endpoint, {
        customerId: customer.id,
        [type]: type === 'email' ? newEmail : newPhone
      });

      setIsVerifying(true);
      setVerificationType(type);
      setSuccessMessage(`Verification code sent to your ${type}. Please check and enter the code.`);
    } catch (err) {
      console.error(`Error initiating ${type} verification:`, err);
      setError(`Failed to send verification code. Please try again.`);
    }
  };

  const submitVerification = async () => {
    try {
      const endpoint = verificationType === 'email'
        ? `${API_CUSTOMER_URL}/add-email`
        : `${API_CUSTOMER_URL}/add-phone`;
      
      const response = await axios.post(endpoint, {
        customerId: customer.id,
        [verificationType]: verificationType === 'email' ? newEmail : newPhone,
        verificationCode
      });

      onUpdateCustomer(response.data.customer);
      setIsVerifying(false);
      setVerificationType(null);
      setVerificationCode('');
      setSuccessMessage(`Your ${verificationType} has been successfully added and verified.`);
    } catch (err) {
      console.error('Error verifying code:', err);
      setError('Invalid verification code. Please try again.');
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
      <div className="p-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">Your Profile</h2>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number</label>
            <input
              id="phone"
              type="tel"
              value={customer.phone}
              disabled
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 bg-gray-100 text-gray-700 sm:text-sm"
            />
          </div>
          
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={!isEditing}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-900"
            />
          </div>
          
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={!isEditing}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-900"
            />
            {customer.email_verified ? (
              <span className="text-green-600 text-xs mt-1">Verified</span>
            ) : (
              <span className="text-red-600 text-xs mt-1">Not verified</span>
            )}
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Barrio Bucks Balance</label>
            <p className="text-2xl font-bold text-indigo-600">${customer.barrio_bucks.toFixed(2)}</p>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">My Tags</label>
            <div className="flex flex-wrap gap-2">
              {allTags.map(tag => (
                <button
                  key={tag}
                  onClick={() => isEditing && handleTagToggle(tag)}
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    tags.includes(tag)
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-200 text-gray-700'
                  } ${isEditing ? 'cursor-pointer hover:bg-indigo-500' : 'cursor-default'}`}
                  disabled={!isEditing}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>
        
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        {successMessage && <p className="mt-2 text-sm text-green-600">{successMessage}</p>}
        
        <div className="mt-6">
          {isEditing ? (
            <div className="space-y-2">
              <button
                onClick={handleSave}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save Changes
              </button>
              <button
                onClick={() => {
                  setIsEditing(false);
                  setName(customer.name);
                  setEmail(customer.email);
                  setTags(customer.tags);
                  setError('');
                  setSuccessMessage('');
                }}
                className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Edit Profile
            </button>
          )}
        </div>

        {!isEditing && (
          <p className="mt-4 text-sm text-gray-600">
            To change your phone number, please contact customer support.
          </p>
        )}

        {!customer.email && (
          <div className="mt-4">
            <label htmlFor="newEmail" className="block text-sm font-medium text-gray-700">Add Email</label>
            <input
              id="newEmail"
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Enter your email"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-900"
            />
            <button
              onClick={() => initiateVerification('email')}
              className="mt-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Verify Email
            </button>
          </div>
        )}

        {!customer.phone && (
          <div className="mt-4">
            <label htmlFor="newPhone" className="block text-sm font-medium text-gray-700">Add Phone</label>
            <input
              id="newPhone"
              type="tel"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              placeholder="Enter your phone number"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-900"
            />
            <button
              onClick={() => initiateVerification('phone')}
              className="mt-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Verify Phone
            </button>
          </div>
        )}

        {isVerifying && (
          <div className="mt-4">
            <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">Verification Code</label>
            <input
              id="verificationCode"
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Enter verification code"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-900"
            />
            <button
              onClick={submitVerification}
              className="mt-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit Verification
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;