// components/ShareAndEarn.js
import React from 'react';

const ShareAndEarn = () => {
  return (
    <div className="component-container">
      <h2 className="component-title">Share & Earn</h2>
      <p className="component-text">Share food pictures and leave reviews to earn points!</p>
    </div>
  );
};

export default ShareAndEarn;