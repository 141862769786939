import React, { useState, useEffect, useRef } from 'react';
import confetti from 'canvas-confetti';

const BarrioBucksBalanceAnimation = ({ initialBalance, finalBalance }) => {
  const [currentBalance, setCurrentBalance] = useState(initialBalance);
  const audioRef = useRef(new Audio('/sounds/cha-ching.mp3'));
  const animationRef = useRef(null);

  useEffect(() => {
    if (initialBalance === finalBalance) return;

    const animationDuration = 2000; // 2 seconds
    const frameDuration = 1000 / 60; // 60 fps
    const totalFrames = Math.round(animationDuration / frameDuration);
    const incrementPerFrame = (finalBalance - initialBalance) / totalFrames;

    let frame = 0;

    const animate = () => {
      if (frame < totalFrames) {
        setCurrentBalance(prevBalance => {
          const newBalance = prevBalance + incrementPerFrame;
          return Number.isFinite(newBalance) ? newBalance : prevBalance;
        });
        frame++;
        animationRef.current = requestAnimationFrame(animate);
      } else {
        setCurrentBalance(finalBalance);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
        audioRef.current.play().catch(e => console.error("Error playing audio:", e));
      }
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [initialBalance, finalBalance]);

  return (
    <div className="text-2xl font-bold text-green-600">
      ${currentBalance.toFixed(2)}
    </div>
  );
};

export default BarrioBucksBalanceAnimation;